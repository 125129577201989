import { BaseApiService } from "@/utils/BaseApiService";
import Link from "next/link";
import { useEffect, useState } from "react";

const Category = () => {

  const [professionalDetailsList, setProfessionalDetailsList] = useState([]);
  const [wealthMindsList, setWealthMindsList] = useState([]);

  // const professionalDetailsList = [
  //   {
  //     "text": "Leadership & Governance",
  //     "link": "#"
  //   },
  //   {
  //     "text": "General Management",
  //     "link": "#"
  //   },
  //   {
  //     "text": "⁠⁠Human Resources",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Finance & Accounting",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Information Technology",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Project Management",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Business Development",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Procurement & Logistics",
  //     "link": "#"
  //   },
  //   {
  //     "text": "⁠⁠International Business",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Oil and Gas",
  //     "link": "#"
  //   }
  // ]


  // const wealthMindsList = [
  //   {
  //     "text": "Entrepreneurship",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Creativity and Innovation",
  //     "link": "#"
  //   },
  //   {
  //     "text": "⁠⁠Personal Finance",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Retirement Planning",
  //     "link": "#"
  //   },
  //   {
  //     "text": "⁠⁠Business Growth",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Wealth Management",
  //     "link": "#"
  //   },
  //   {
  //     "text": "Investment Clubs",
  //     "link": "#"
  //   }
  // ]

  const fetchProfessionTrainingCategories = async () => {
    let searchParameters = { searchTerm: "", offset: 0, limit: 0, commaSeparatedTypeIds: "0", commaSeparatedAcademyIds: "0" };
    try {
      const response = await new BaseApiService("/categories").getRequestWithJsonResponse(
        searchParameters
      );
      console.log("Professional Training Response:", response);
      if (response && response.records) {
        setProfessionalDetailsList(response.records);
        console.log("Professional Training set:", response.records); // Log the state update
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("fetchProfessionTrainingCategories:fetchDataFromServer>>>>", error);
    }
  };

  const fetchWealthyMindCategories = async () => {
    let searchParameters = { searchTerm: "", offset: 0, limit: 0, commaSeparatedTypeIds: "0", commaSeparatedAcademyIds: "1" };
    try {
      const response = await new BaseApiService("/categories").getRequestWithJsonResponse(
        searchParameters
      );
      console.log("Wealthy minds Response:", response);
      if (response && response.records) {
        setWealthMindsList(response.records);
        console.log("Wealthy minds set:", response.records); // Log the state update
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("fetchWealthyMindCategories:fetchDataFromServer>>>>", error);
    }
  };

  useEffect(() => {
    fetchProfessionTrainingCategories();
    fetchWealthyMindCategories();
  }, []);


  return (
    <div className="rbt-category-menu-wrapper rbt-category-update">
      <div className="rbt-category-btn">
        <div className="rbt-offcanvas-trigger md-size icon">
          <span className="d-none d-xl-block">
            <i className="feather-grid"></i>
          </span>
          <i title="Category" className="feather-grid d-block d-xl-none"></i>
        </div>
        <span className="category-text d-none d-xl-block">Category</span>
      </div>

      <div className="update-category-dropdown">
        <div className="inner">
          <ul className="dropdown-parent-wrapper">
            <SingleCategory
              title="Professional Training"
              isActive={false}
              categoryDataList={professionalDetailsList}
            />
            <SingleCategory
              title="Wealthy Minds Academy"
              isActive={false}
              categoryDataList={wealthMindsList}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

const SingleCategory = ({ title, categoryDataList, isActive }) => {
  return (
    <li className={`dropdown-parent-list ${isActive ? "active" : ""}`}>
      <Link href="#">{title}</Link>
      <div className="dropdown-child-wrapper">
        <div className="child-inner">
          {categoryDataList?.map((cate, index) => (
            <div className="dropdown-child-list" key={index}>
              <Link href={""+cate.id}>{cate.name}</Link>
            </div>
          ))}
        </div>
      </div>
    </li>
  );
}

export default Category;