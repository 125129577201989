import Image from "next/image";
import Link from "next/link";
import { useAppContext } from "@/context/Context";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import StorageParams from "@/constants/StorageParams";

const HeaderTopEight = ({
  bgColor,
  gapSpaceBetween,
  container,
  flexDirection,
}) => {

  const headerTopDetails = {
    "insFollowers": 100,
    "fbFollowers": 500,
    "type": "Followers",
    "phone": "+1-202-555-0174",
    "introPrice": "Intro price. Get Histudy for Big Sale -95% off.",
    "badgeText": "Hot",
    "limit": "Limited Time Offer",
    "img": "/images/icons/hand-emojji.svg",
    "language": [
      {
        "defaultTitle": "English",
        "img": "/images/icons/en-us.png",
        "link": "#",
        "subLng": [
          {
            "name": "English",
            "img": "/images/icons/en-us.png",
            "link": "#"
          }
        ]
      }
    ],
    "trainingLocation": [
      {
        "defaultTitle": "Our Training Locations",
        "img": "/images/icons/en-us.png",
        "link": "#",
        "subLng": [
          {
            "name": "Uganda",
            "img": "/images/icons/fr.png",
            "link": "#"
          },
          {
            "name": "Kenya",
            "img": "/images/icons/fr.png",
            "link": "#"
          },
          {
            "name": "Tanzania",
            "img": "/images/icons/fr.png",
            "link": "#"
          },
          {
            "name": "South Sudan",
            "img": "/images/icons/fr.png",
            "link": "#"
          },
          {
            "name": "Malawi",
            "img": "/images/icons/fr.png",
            "link": "#"
          },
          {
            "name": "Zambia",
            "img": "/images/icons/fr.png",
            "link": "#"
          },
          {
            "name": "Ghana",
            "img": "/images/icons/fr.png",
            "link": "#"
          }
        ]
      }
    ],
    "currency": [
      {
        "defaultCurrency": "USD",
        "link": "#",
        "subCurr": [
          {
            "currency": "EUR",
            "link": "#"
          },
          {
            "currency": "GBP",
            "link": "#"
          }
        ]
      }
    ]
  };

  const { toggle, setToggle } = useAppContext();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const router = useRouter();

  const handleLogout = () => {
    if (typeof window !== "undefined") {
      // Clear session storage and local storage
      localStorage.removeItem(StorageParams.IS_LOGGED_IN);
      sessionStorage.clear();
      // Redirect to the login page
      router.push('/login');
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Check if we are in the browser
      const user = localStorage.getItem(StorageParams.IS_LOGGED_IN);
      setLoggedInUser(user);
      console.log("Logged in user", user);
    }
  }, []);

  console.log("Logged in user " + loggedInUser);

  return (
    <div
      className={`rbt-header-top rbt-header-top-1 ${gapSpaceBetween} ${bgColor} top-expended-activation ${!toggle ? "d-none" : ""
        }`}
    >
      <div className={`${container}`}>
        <div className="top-expended-wrapper">
          <div
            className={`top-expended-inner rbt-header-sec align-items-center ${flexDirection}`}
          >
            <div className="rbt-header-sec-col rbt-header-left d-none d-xl-block">
              <div className="rbt-header-content">

                <div className="header-info">
                  <ul className="rbt-dropdown-menu switcher-language">
                    {headerTopDetails?.trainingLocation.map((lng, innerIndex) => (
                      <li className="has-child-menu" key={innerIndex}>
                        <Link href={lng.link}>
                          <Image
                            className="left-image"
                            src={lng.img}
                            width={20}
                            height={13}
                            alt={`${lng.name} Images`}
                          />
                          <span className="menu-item">
                            {lng.defaultTitle}
                          </span>
                          <i className="right-icon feather-chevron-down"></i>
                        </Link>

                        <ul className="sub-menu">
                          {lng.subLng.map((sublng, innerIndex) => (
                            <li key={innerIndex}>
                              <Link href={sublng.link}>
                                <Image
                                  className="left-image"
                                  src={sublng.img}
                                  width={20}
                                  height={13}
                                  alt={`${sublng.name} Images`}
                                />
                                <span className="menu-item">
                                  {sublng.name}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="rbt-separator d-none d-xl-block"></div>
                <Link href="/pages/faqs" style={{ fontSize: '14px', fontWeight: 500, color: '#ffffffab' }}>
                  Have Questions
                </Link>
                <div className="rbt-separator d-none d-xl-block"></div>
                <Link href="/contact" style={{ fontSize: '14px', fontWeight: 500, color: '#ffffffab' }}>
                  Contact Us
                </Link>
              </div>
            </div>


            <div className="rbt-header-sec-col rbt-header-right mt_md--10 mt_sm--10">
              <div className="rbt-header-content justify-content-start justify-content-lg-end">
                <div className="rbt-separator d-none d-xl-block"></div>
                <div className="header-info">
                  <ul className="rbt-dropdown-menu switcher-language">
                    {headerTopDetails?.language.map((lng, innerIndex) => (
                      <li className="has-child-menu" key={innerIndex}>
                        <Link href={lng.link}>
                          <Image
                            className="left-image"
                            src={lng.img}
                            width={20}
                            height={13}
                            alt={`${lng.name} Images`}
                          />
                          <span className="menu-item">
                            {lng.defaultTitle}
                          </span>
                          <i className="right-icon feather-chevron-down"></i>
                        </Link>

                        <ul className="sub-menu">
                          {lng.subLng.map((sublng, innerIndex) => (
                            <li key={innerIndex}>
                              <Link href={sublng.link}>
                                <Image
                                  className="left-image"
                                  src={sublng.img}
                                  width={20}
                                  height={13}
                                  alt={`${sublng.name} Images`}
                                />
                                <span className="menu-item">
                                  {sublng.name}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="rbt-separator d-none d-xl-block"></div>
                <div className="header-info" style={{ display: loggedInUser ? 'none' : 'block' }}>
                  <Link href="/login" style={{ fontSize: '14px', fontWeight: 500, color: '#ffffffab', display: (loggedInUser === true) ? 'none' : 'block' }}>
                    <i className="feather-search"></i>
                    Login
                  </Link>
                </div>
                <div className="header-info" style={{ display: loggedInUser ? 'block' : 'none' }}>
                  <button onClick={handleLogout} style={{ fontSize: '14px', fontWeight: 500, color: '#ffffffab', background: 'none', border: 'none', cursor: 'pointer' }}>
                    <i className="feather-search"></i>
                    Logout
                  </button>
                </div>
                <div className="rbt-separator d-none d-xl-block"></div>
                <div className="header-info" style={{ display: loggedInUser ? 'none' : 'block' }}>
                  <Link href="/register" style={{ fontSize: '14px', fontWeight: 500, color: '#ffffffab' }}>
                    <i className="feather-search ml_md--2"></i>
                    Register
                  </Link>

                </div>

              </div>
            </div>
          </div>
          <div className="header-info">
            <div className="top-bar-expended d-block d-lg-none">
              <button
                className="topbar-expend-button rbt-round-btn"
                onClick={() => setToggle(!toggle)}
              >
                <i className="feather-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopEight;
